<template />

<script>
import { computed } from 'vue'
import { useAuthStore } from '../../stores/auth'

export default {
  name: 'ProfitWellRetain',
  setup() {
    const authStore = useAuthStore()
    return {
      authenticated: computed(() => authStore.check),
      user: computed(() => authStore.user),
    }
  },

  data() {
    return {
      loaded: false,
    }
  },

  computed: {
    isIframe() {
      return window.location !== window.parent.location || window.frameElement
    },
  },

  watch: {
    user() {
      if (this.user)
        this.loadScript()
    },
  },

  mounted() {
    window.profitwell
      = window.profitwell
      || function () {
        (window.profitwell.q = window.profitwell.q || []).push(arguments)
      }
    this.loadScript()
  },

  methods: {
    loadScript() {
      if (this.isIframe || import.meta.server)
        return

      if (!this.loaded) {
        const script = document.createElement('script')
        script.setAttribute(
          'src',
          'https://public.profitwell.com/js/profitwell.js?auth=42b115a720f6485e269997d9b7090efa',
        )
        script.setAttribute('data-pw-auth', '42b115a720f6485e269997d9b7090efa')
        script.setAttribute('id', 'profitwell-js')
        script.setAttribute('async', 1)
        script.addEventListener('load', () => {
          this.loaded = true
        })
        document.head.appendChild(script)
      }

      if (this.authenticated)
        window.profitwell('start', { user_email: this.user.email })
    },
  },
}
</script>

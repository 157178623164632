<template />

<script>
import { computed } from 'vue'
import { useAuthStore } from '../../stores/auth'

export default {
  name: 'Hotjar',

  setup() {
    const authStore = useAuthStore()
    return {
      authenticated: computed(() => authStore.check),
      user: computed(() => authStore.user),
      isIframe: useIsIframe(),
    }
  },

  data() {
    return {
      loaded: false,
    }
  },

  watch: {
    authenticated() {
      // Only register new users
      if (this.authenticated && this.user.just_registered)
        this.loadHotjar()
    },
  },

  mounted() {
    if (import.meta.server)
      return
    this.loadHotjar()
  },

  methods: {
    loadHotjar() {
      if (!this.authenticated || this.isIframe || this.loaded)
        return;

      (function (h, o, t, j, a, r) {
        h.hj
          = h.hj
          || function () {
            (h.hj.q = h.hj.q || []).push(arguments)
          }
        h._hjSettings = { hjid: 2449591, hjsv: 6 }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script')
        r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
      this.loaded = true
    },
  },
}
</script>

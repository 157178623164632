// Get user from store
import clonedeep from 'clone-deep'
import { useAuthStore } from '../stores/auth'

const defaultCurrency = 'usd'
const currencySymbols = {
  usd: '$',
  eur: '€',
  gbp: '£'
}
export const prices = {
  usd: {
    free: {
      monthly: 0,
      yearly: 0
    },
    enterprise: {
      monthly: 59,
      yearly: 49
    },
    default: {
      monthly: 24,
      yearly: 20
    }
  },
  eur: {
    free: {
      monthly: 0,
      yearly: 0
    },
    enterprise: {
      monthly: 59,
      yearly: 49
    },
    default: {
      monthly: 24,
      yearly: 20
    }
  },
  gbp: {
    free: {
      monthly: 0,
      yearly: 0
    },
    enterprise: {
      monthly: 49,
      yearly: 40
    },
    default: {
      monthly: 19,
      yearly: 17
    }
  }
}

function getFallbackCurrency () {
  // Get currency from timezone
  try {
    const timeZone = Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.toLowerCase()
    if (timeZone.includes('london'))
      return 'gbp'
    else if (timeZone.includes('europe'))
      return 'eur'
  } catch (e) {}
  return defaultCurrency
}

export function getCurrency () {
  const authStore = useAuthStore()
  const user = authStore.user

  return user?.preferred_currency || getFallbackCurrency()
}

export function getFormattedPrices (currency) {
  const newPrices = clonedeep(prices)[currency]

  for (const plan in newPrices) {
    for (const period in newPrices[plan])
      newPrices[plan][period] = formatPrice(newPrices[plan][period], currency)
  }
  return newPrices
}

export function formatPrice (price, currency) {
  if (currency === 'eur')
    return price + currencySymbols[currency]

  return currencySymbols[currency] + price
}

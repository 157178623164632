<template />

<script setup>
const rewardfulId = '4f7331'
const isIframe = useIsIframe()

function initRewardful () {
  (function (w, r) {
    w._rwq = r
    w[r] = w[r] || function () {(w[r].q = w[r].q || []).push(arguments)}
  })(window, 'rewardful')
}

function loadScript () {
  if (isIframe || document.querySelector('#rewardful-js'))
    return

  const script = document.createElement('script')
  script.async = true
  script.src = 'https://affiliates.noteforms.com/rw.js'
  script.dataset.rewardful = rewardfulId
  script.setAttribute('id', 'rewardful-js')
  document.head.appendChild(script)
}

onMounted(() => {
  if (import.meta.server)
    return
  initRewardful()
  loadScript()
})
</script>

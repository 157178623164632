import { default as affiliatesVvrk9RN4ALMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/affiliates.vue?macro=true";
import { default as database_45viewsKgCChOQNiTMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/database-views.vue?macro=true";
import { default as discount_45students_45academics_45ngosSkOSytA6BmMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/discount-students-academics-ngos.vue?macro=true";
import { default as _91slug_93f8ePAM5dyLMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/features/[slug].vue?macro=true";
import { default as indexWOCcMQUgqnMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/features/index.vue?macro=true";
import { default as _91slug_93Ni5w19m74wMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/form-templates/[slug].vue?macro=true";
import { default as indexBVHcflH0S3Meta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/form-templates/index.vue?macro=true";
import { default as _91slug_93UW3sCjHJQvMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/form-templates/industries/[slug].vue?macro=true";
import { default as _91slug_93lQIeueSvNmMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/form-templates/types/[slug].vue?macro=true";
import { default as editiGjy5q2gnAMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexU8In9km0r1Meta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analyticsgFZQXpPrWyMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexuklg7e9uaiMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexv8EfwPyi2ZMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as sharetnI4zWHL8AMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showricbW5zJgCMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indextInw3r2I9KMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/forms/create/index.vue?macro=true";
import { default as uploadsuAA0aIGUVkMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/forms/uploads.vue?macro=true";
import { default as guidesRKbmcc4Q45Meta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/guides.vue?macro=true";
import { default as homepNhkTbEpg2Meta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/home.vue?macro=true";
import { default as indexWuMuu3ek57Meta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/index.vue?macro=true";
import { default as integrationsOkPxotzjYXMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/integrations.vue?macro=true";
import { default as loginFeEO6nQyblMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/login.vue?macro=true";
import { default as my_45templates70xXthb3EZMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/my-templates.vue?macro=true";
import { default as notion_45ambassadors_45influencersJmfSHdxXGcMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/notion-ambassadors-influencers.vue?macro=true";
import { default as _91slug_93lQ4sJdte0BMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/notion-form/[slug].vue?macro=true";
import { default as callbackmBuscyFbvFMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/notion/callback.vue?macro=true";
import { default as guest_45callbackyIuY6hflJkMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/notion/guest-callback.vue?macro=true";
import { default as callbackLIMl3y4U7TMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/oauth/callback.vue?macro=true";
import { default as partnersYsLl1TIsCxMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/partners.vue?macro=true";
import { default as _91token_93qnALdGH5X8Meta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/password/reset/[token].vue?macro=true";
import { default as indexW4wJLLLg0eMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricingQqaYT9GLQaMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyDVt90IYY5nMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/privacy-policy.vue?macro=true";
import { default as register3w4prtUPcIMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/register.vue?macro=true";
import { default as access_45tokensWbkRwyBxmvMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountiBsUTNz9wkMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/settings/account.vue?macro=true";
import { default as adminCtwKKHNL6eMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/settings/admin.vue?macro=true";
import { default as billingB4ixremZ1ZMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/settings/billing.vue?macro=true";
import { default as connectionskKtIycD0UIMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domainv1MlVMVPJdMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as email_45settingsxVZcgJLcTPMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/settings/email-settings.vue?macro=true";
import { default as indexTgoBp2jqHDMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/settings/index.vue?macro=true";
import { default as passwordK8yWv0GcRwMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/settings/password.vue?macro=true";
import { default as profile5muQEUX8DcMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/settings/profile.vue?macro=true";
import { default as workspacesqkW9EDTuF9Meta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingsaTGZHAe5AlMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/settings.vue?macro=true";
import { default as errorSkgGu2MlLsMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successHXMun5WDK6Meta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditionsY96tz7feMiMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/terms-conditions.vue?macro=true";
import { default as editgXP6BUSkMkMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/views/[slug]/edit.vue?macro=true";
import { default as indexyEUgyeDzxtMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/views/[slug]/index.vue?macro=true";
import { default as index26E7rJodR9Meta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/views/[slug]/show/index.vue?macro=true";
import { default as shareLpG7Lx17PdMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/views/[slug]/show/share.vue?macro=true";
import { default as showKk37NOTnARMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/views/[slug]/show.vue?macro=true";
import { default as indexyTgRmc9YBuMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/views/create/index.vue?macro=true";
import { default as indexRRz2JKjvTnMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/views/index.vue?macro=true";
import { default as uploadsxeaNw6DNyaMeta } from "/codebuild/output/src1512811647/src/notionforms/client/pages/views/uploads.vue?macro=true";
export default [
  {
    name: "affiliates",
    path: "/affiliates",
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/affiliates.vue")
  },
  {
    name: "database-views",
    path: "/database-views",
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/database-views.vue")
  },
  {
    name: "discount-students-academics-ngos",
    path: "/discount-students-academics-ngos",
    meta: discount_45students_45academics_45ngosSkOSytA6BmMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/discount-students-academics-ngos.vue")
  },
  {
    name: "features-slug",
    path: "/features/:slug()",
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/features/[slug].vue")
  },
  {
    name: "features",
    path: "/features",
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/features/index.vue")
  },
  {
    name: "form-templates-slug",
    path: "/form-templates/:slug()",
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/form-templates/[slug].vue")
  },
  {
    name: "form-templates",
    path: "/form-templates",
    meta: indexBVHcflH0S3Meta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/form-templates/index.vue")
  },
  {
    name: "form-templates-industries-slug",
    path: "/form-templates/industries/:slug()",
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/form-templates/industries/[slug].vue")
  },
  {
    name: "form-templates-types-slug",
    path: "/form-templates/types/:slug()",
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/form-templates/types/[slug].vue")
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editiGjy5q2gnAMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/forms/[slug]/edit.vue")
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/forms/[slug]/index.vue")
  },
  {
    name: showricbW5zJgCMeta?.name,
    path: "/forms/:slug()/show",
    meta: showricbW5zJgCMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/forms/[slug]/show.vue"),
    children: [
  {
    name: "forms-slug-show-analytics",
    path: "analytics",
    meta: analyticsgFZQXpPrWyMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/forms/[slug]/show/analytics.vue")
  },
  {
    name: "forms-slug-show",
    path: "",
    meta: indexuklg7e9uaiMeta || {},
    redirect: indexuklg7e9uaiMeta?.redirect,
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/forms/[slug]/show/index.vue")
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexv8EfwPyi2ZMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue")
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: sharetnI4zWHL8AMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/forms/[slug]/show/share.vue")
  }
]
  },
  {
    name: "forms-create",
    path: "/forms/create",
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/forms/create/index.vue")
  },
  {
    name: "forms-uploads",
    path: "/forms/uploads",
    meta: uploadsuAA0aIGUVkMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/forms/uploads.vue")
  },
  {
    name: "guides",
    path: "/guides",
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/guides.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: homepNhkTbEpg2Meta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexWuMuu3ek57Meta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/index.vue")
  },
  {
    name: "integrations",
    path: "/integrations",
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/integrations.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginFeEO6nQyblMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/login.vue")
  },
  {
    name: "my-templates",
    path: "/my-templates",
    meta: my_45templates70xXthb3EZMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/my-templates.vue")
  },
  {
    name: "notion-ambassadors-influencers",
    path: "/notion-ambassadors-influencers",
    meta: notion_45ambassadors_45influencersJmfSHdxXGcMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/notion-ambassadors-influencers.vue")
  },
  {
    name: "notion-form-slug",
    path: "/notion-form/:slug()",
    meta: _91slug_93lQ4sJdte0BMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/notion-form/[slug].vue")
  },
  {
    name: "notion-callback",
    path: "/notion/callback",
    meta: callbackmBuscyFbvFMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/notion/callback.vue")
  },
  {
    name: "notion-guest-callback",
    path: "/notion/guest-callback",
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/notion/guest-callback.vue")
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    meta: callbackLIMl3y4U7TMeta || {},
    alias: ["/oauth/:provider/callback"],
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/oauth/callback.vue")
  },
  {
    name: "partners",
    path: "/partners",
    meta: partnersYsLl1TIsCxMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/partners.vue")
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93qnALdGH5X8Meta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/password/reset/[token].vue")
  },
  {
    name: "password-reset",
    path: "/password/reset",
    meta: indexW4wJLLLg0eMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/password/reset/index.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingQqaYT9GLQaMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/pricing.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyDVt90IYY5nMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: register3w4prtUPcIMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/register.vue")
  },
  {
    name: settingsaTGZHAe5AlMeta?.name,
    path: "/settings",
    meta: settingsaTGZHAe5AlMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/settings.vue"),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/settings/access-tokens.vue")
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountiBsUTNz9wkMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/settings/account.vue")
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminCtwKKHNL6eMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/settings/admin.vue")
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingB4ixremZ1ZMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/settings/billing.vue")
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionskKtIycD0UIMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/settings/connections.vue")
  },
  {
    name: "settings-custom-domain",
    path: "custom-domain",
    meta: custom_45domainv1MlVMVPJdMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/settings/custom-domain.vue")
  },
  {
    name: "settings-email-settings",
    path: "email-settings",
    meta: email_45settingsxVZcgJLcTPMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/settings/email-settings.vue")
  },
  {
    name: "settings",
    path: "",
    meta: indexTgoBp2jqHDMeta || {},
    redirect: indexTgoBp2jqHDMeta?.redirect,
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/settings/index.vue")
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordK8yWv0GcRwMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/settings/password.vue")
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profile5muQEUX8DcMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/settings/profile.vue")
  },
  {
    name: "settings-workspaces",
    path: "workspaces",
    meta: workspacesqkW9EDTuF9Meta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/settings/workspaces.vue")
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: errorSkgGu2MlLsMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/subscriptions/error.vue")
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successHXMun5WDK6Meta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/subscriptions/success.vue")
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsY96tz7feMiMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/terms-conditions.vue")
  },
  {
    name: "views-slug-edit",
    path: "/views/:slug()/edit",
    meta: editgXP6BUSkMkMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/views/[slug]/edit.vue")
  },
  {
    name: "views-slug",
    path: "/views/:slug()",
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/views/[slug]/index.vue")
  },
  {
    name: showKk37NOTnARMeta?.name,
    path: "/views/:slug()/show",
    meta: showKk37NOTnARMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/views/[slug]/show.vue"),
    children: [
  {
    name: "views-slug-show",
    path: "",
    meta: index26E7rJodR9Meta || {},
    redirect: index26E7rJodR9Meta?.redirect,
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/views/[slug]/show/index.vue")
  },
  {
    name: "views-slug-show-share",
    path: "share",
    meta: shareLpG7Lx17PdMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/views/[slug]/show/share.vue")
  }
]
  },
  {
    name: "views-create",
    path: "/views/create",
    meta: indexyTgRmc9YBuMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/views/create/index.vue")
  },
  {
    name: "views",
    path: "/views",
    meta: indexRRz2JKjvTnMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/views/index.vue")
  },
  {
    name: "views-uploads",
    path: "/views/uploads",
    meta: uploadsxeaNw6DNyaMeta || {},
    component: () => import("/codebuild/output/src1512811647/src/notionforms/client/pages/views/uploads.vue")
  }
]
<template>
  <InputWrapper v-bind="inputWrapperProps">
    <template #label>
      <slot name="label" />
    </template>

    <v-select
      ref="vselect"
      v-model="compVal"
      :data="filteredRelations"
      :label="label"
      option-key="id"
      emit-key="id"
      :required="required"
      :loading="loading"
      :multiple="multiple"
      searchable
      :remote="remoteSearch"
      :color="color"
      :placeholder="placeholder"
      :uppercase-labels="uppercaseLabels"
      :theme="theme"
      :has-error="hasError"
      :allow-creation="allowCreation"
      :should-allow-creation="shouldAllowCreation"
      :disabled="disabled"
      :help="help"
      :help-position="helpPosition"
      :dropdown-class="dropdownClass"
      :clearable="clearable"
      :show-clear-button="false"
      @update:model-value="select"
      @update-options="updateOptions"
    >
      <template #selected="{ option, toggle }">
        <div
          v-if="multiple"
          class="flex gap-1 flex-wrap items-center pr-4 w-full"
        >
          <div
            v-for="item in selectedOptions(option)"
            :key="item.id"
            class="py-1 pl-1 rounded flex gap-x-2 bg-gray-100 border border-gray-300 dark:bg-gray-500 text-xs flex-grow max-w-full truncate text-center items-center"
          >
            <NotionPageIcon
              v-if="showIcon"
              :page="item"
            />
            <span>
              {{
                item.label !== null && item.label !== '' ? item.label : 'Untitled'
              }}
            </span>
            <button
              class="cursor-pointer group px-1 -ml-1"
              @click.prevent="toggle(item)"
            >
              <Icon
                v-if="!disabled"
                name="heroicons:x-mark-16-solid"
                class=" w-4 h-4 text-gray-300 group-hover:text-gray-600 group-hover:dark:text-gray-400 dark:text-white"
              />
            </button>
          </div>
          <div class="h-0 flex-grow-[100]" />
        </div>
        <div
          v-for="item in selectedOptions(option)"
          v-else
          :key="item.id"
          class="flex items-center gap-x-2"
        >
          <NotionPageIcon
            v-if="showIcon"
            :page="item"
          />
          <span>
            {{
              item.label !== null && item.label !== '' ? item.label : 'Untitled'
            }}
          </span>
        </div>
      </template>
      <template #option="{ option, selected }">
        <span
          class="flex items-center gap-x-2 dark:text-white cursor-pointer"
        >
          <NotionPageIcon
            v-if="showIcon"
            :page="option"
          />
          {{
            option.label !== null && option.label !== ''
              ? option.label
              : 'Untitled'
          }}
        </span>

        <span
          v-if="selected"
          class="absolute inset-y-0 right-0 flex items-center pr-4 dark:text-white"
        >
          <Icon
            name="heroicons:check-16-solid"
            class="w-5 h-5"
          />
        </span>
      </template>
      <template
        v-if="!searchText && filteredRelations.length>=100"
        #after-options
      >
        <div
          class="border-t border-gray-300 p-1"
        >
          <p
            class="w-full text-gray-500 text-center py-2"
          >
            Options not showing? Use the search bar.
          </p>
        </div>
      </template>
    </v-select>

    <template #help>
      <slot name="help" />
    </template>

    <template #error>
      <slot name="error" />
    </template>
  </InputWrapper>
</template>


<script>
import clonedeep from 'clone-deep'
import { inputProps, useFormInput } from '../useFormInput.js'
import InputWrapper from '../components/InputWrapper.vue'
import NotionPageIcon from '~/components/notion/pages/NotionPageIcon.vue'
import Fuse from 'fuse.js'

export default {
  name: 'RelationInput',
  components: { NotionPageIcon, InputWrapper },
  props: {
    ...inputProps,
    relation: { type: Object, required: false },
    workspaceId: { type: Number, required: false },
    formSlug: { type: String, required: false },
    viewSlug: { type: String, required: false },
    propertyId: { type: String, required: false },
    filter: { type: Object, required: false },
    usePrivateEndpoint: { type: Boolean, required: false, default: true },
    displayedPropertyId: { type: String, required: false, default: 'title' },
    displayedPropertyType: { type: String, required: false, default: 'title' },
    multiple: { type: Boolean, required: false, default: true },
    showIcon: { type: Boolean, required: false, default: true },
    allowCreation: { type: Boolean, required: false, default: false },
    useFormRecordsEndpoint: { type: Boolean, required: false, default: false },
    dropdownClass: { type: String, default: 'w-full' },
    clearable: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    const relationsStore = useNotionRelationsStore()
    relationsStore.initForInput(props.name)
    return {
      relationsStore,
      ...useFormInput(props, context)
    }
  },
  data () {
    return {
      searchText: '',
      extraRecordsFetched: false,
      pendingRequest: null,
      abortController: null
    }
  },
  computed: {
    loading () {
      return this.relationsStore.loadingItems[this.name]
    },

    relations () {
      return clonedeep(this.relationsStore.getByKey(this.name))?.relations ?? []
    },
    fetchUrl () {
      let ids = null
      if (this.compVal) {
        if (Array.isArray(this.compVal))
          ids = encodeURIComponent(this.compVal.join(','))
        else
          ids = encodeURIComponent(this.compVal)
      }
      if (this.useFormRecordsEndpoint) {
        return '/forms/{slug}/search-records{search}{queryparam}'
          .replace('{slug}', this.formSlug)
          .replace(
            '{search}',
            this.searchText ? `/${encodeURIComponent(this.searchText)}` : ''
          )
          .replace('{queryparam}', ids ? `?ids=${ids}` : '')
      } else if (this.usePrivateEndpoint) {
        return '/notion/workspaces/{workspaceId}/databases/{databaseId}/search-records{search}{queryparam}'
          .replace('{workspaceId}', this.workspaceId)
          .replace('{databaseId}', this.relation.database_id)
          .replace(
            '{search}',
            this.searchText ? `/${encodeURIComponent(this.searchText)}` : ''
          )
          .replace('{queryparam}', ids ? `?ids=${ids}` : '')
      } else if (this.viewSlug) {
        return '/views/{slug}/property/{propertyId}/search-relation{search}{queryparam}'
          .replace('{slug}', this.viewSlug)
          .replace('{propertyId}', encodeURIComponent(this.propertyId))
          .replace(
            '{search}',
            this.searchText ? `/${encodeURIComponent(this.searchText)}` : ''
          )
          .replace('{queryparam}', ids ? `?ids=${ids}` : '')
      } else {
        return '/forms/{slug}/property/{propertyId}/search-relation{search}{queryparam}'
          .replace('{slug}', this.formSlug)
          .replace('{propertyId}', encodeURIComponent(this.propertyId))
          .replace(
            '{search}',
            this.searchText ? `/${encodeURIComponent(this.searchText)}` : ''
          )
          .replace('{queryparam}', ids ? `?ids=${ids}` : '')
      }
    },
    hasMissingRelations () {
      return this.compVal && this.relations.length > 0 && this.compVal.length > 0 && this.relations.filter((item) => {
        return this.compVal.includes(item.id)
      }).length !== this.compVal.length
    },
    /**
     * Filter, order etc relations
     */
    filteredRelations () {
      if (!this.searchText) {
        return clonedeep(this.relations).sort((a, b) => {
          return a.label > b.label ? 1 : -1
        })
      }

      // when searching only for numbers, search for exact matches
      const searchingNumbersOnly = /^\d+$/.test(this.searchText)

      // Fuze search
      const fuse = new Fuse(this.relations, {
        keys: ['label'],
        ignoreLocation: true,
        useExtendedSearch: searchingNumbersOnly
      })
      return fuse.search((searchingNumbersOnly ? '\'' : '') + this.searchText).map((res) => {
        return res.item
      }).slice(0, 10)
    }
  },
  watch: {
    compVal (val, oldVal) {
      if (!oldVal && this.hasMissingRelations) {
        // Reset to fetch records explicitely
        if (this.abortController) {
          this.abortController.abort()
        }
        this.extraRecordsFetched = false
        this.loadRelations(true)
      }
    }
  },
  mounted () {
    if (!this.multiple && Array.isArray(this.compVal))
      this.compVal = this.compVal[0] ?? null

    if (!this.relationsStore.allLoaded[this.name]) {
      this.loadRelations()
    }
  },
  methods: {
    loadRelations (force = false) {
      let requestOptions
      if (!force && this.loading)
        return

      this.abortController = new AbortController()
      if (this.usePrivateEndpoint) {
        requestOptions = {
          method: 'POST',
          body: {
            filter: this.filter,
            displayed_property_id: this.displayedPropertyId,
            displayed_property_type: this.displayedPropertyType
          },
          signal: this.abortController.signal
        }
        this.relationsStore.loadAll(this.name, this.fetchUrl, requestOptions).catch(() => {
          useAlert().error('Error loading relations.')
        })
      } else {
        requestOptions = {
          signal: this.abortController.signal
        }
        this.relationsStore.loadAll(this.name, this.fetchUrl, requestOptions).catch(() => {
          useAlert().error('Error loading relations.')
        })
      }
    },
    select (value) {
      this.$emit('update:modelValue', value)
    },
    remoteSearch (val) {
      this.searchText = val
      this.loadRelations()
    },
    selectedOptions (ids) {
      return this.relations.filter((item) => {
        if (this.multiple)
          return ids.includes(item.id)
        else
          return item.id === ids
      })
    },
    updateOptions (newItem) {
      if (newItem) {
        this.relationsStore.createRelationItem(this.name, {
          icon: '',
          icon_type: '',
          id: newItem.name,
          label: newItem.name
        })
      }
    },
    shouldAllowCreation (search) {
      if (!this.allowCreation)
        return false
      if (!this.compVal)
        return true
      if (!search)
        return false

      if (this.multiple) {
        return (
          this.compVal.filter((item) => {
            return item.label === search
          }).length === 0
        )
      } else {
        return this.compVal !== search
      }
    }
  }
}
</script>

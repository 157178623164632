import { defineStore } from 'pinia'
import { useContentStore } from '~/composables/stores/useContentStore.js'

export const workspaceEndpoint = '/notion/workspaces/'

export const useWorkspacesStore = defineStore('workspaces', () => {

  const storedWorkspaceId = useCookie('currentWorkspace')

  const contentStore = useContentStore()
  const currentId = ref(storedWorkspaceId)

  const getCurrent = computed(() => {
    return contentStore.getByKey(currentId.value)
  })

  const isSubscribed =  computed(()=>{
    return getCurrent.value?.is_pro || getCurrent.value?.is_enterprise
  })

  const setCurrentId = (id) => {
    currentId.value = id
    storedWorkspaceId.value = id
  }

  const set = (items) => {
    contentStore.content.value = new Map
    save(items)
  }

  const save = (items) => {
    contentStore.save(items)
    if ((getCurrent.value == null) && contentStore.length.value) {
      setCurrentId(items[0].id)
    } else if (contentStore.length.value === 0) {
      setCurrentId(null)
    }
  }

  const remove = (itemId) => {
    contentStore.remove(itemId)
    if (currentId.value === itemId) {
      setCurrentId(contentStore.length.value > 0 ? contentStore.getAll.value[0].id : null)
    }
  }

  return {
    ...contentStore,
    currentId,
    getCurrent,
    isSubscribed,
    setCurrentId,
    set,
    save,
    remove
  }
})

export const fetchAllWorkspaces = (options = {}) => {
  return useNoteFormsApi(workspaceEndpoint, options)
}

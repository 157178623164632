import { onUnmounted, ref } from 'vue'

export function useConfetti() {
  const timeoutId = ref(null)
  const nuxtApp = useNuxtApp()
  const $confetti = nuxtApp.vueApp.config.globalProperties.$confetti

  async function play(duration = 3000) {
    $confetti.start({ defaultSize: 6 })
    timeoutId.value = setTimeout(() => {
      $confetti.stop()
    }, duration)
  }

  function stop() {
    $confetti.stop()
  }

  onUnmounted(() => {
    if (timeoutId.value)
      clearTimeout(timeoutId)
  })

  return {
    play,
    stop,
  }
}

<template>
  <div>
    <toggle-switch-input
      v-model="compVal.include_submission_data"
      name="include_submission_data"
      class="mt-4"
      label="Include submission data"
      help="With form submission answers"
    />
    <toggle-switch-input
      v-model="compVal.link_see_notion"
      name="link_see_notion"
      class="mt-4"
      label="'See in Notion' Link"
      help="Link to the Notion page created by this form submission"
    />
    <toggle-switch-input
      v-model="compVal.link_open_form"
      name="link_open_form"
      class="mt-4"
      label="'Open Form' Link"
      help="Link to the form public page"
    />
    <toggle-switch-input
      v-model="compVal.link_edit_form"
      name="link_edit_form"
      class="mt-4"
      label="'Edit Form' Link"
      help="Link to the form admin page"
    />
    <toggle-switch-input
      v-model="compVal.views_submissions_count"
      name="views_submissions_count"
      class="mt-4"
      label="Form Analytics"
      help="Form views and submissions count"
    />
    <toggle-switch-input
      v-model="compVal.link_edit_submission"
      name="link_edit_submission"
      class="mt-4"
    >
      <template #label>
        Link to the Edit Submission Record
        <EnterpriseTag
          class="ml-2"
          upgrade-modal-title="Upgrade for Editable Submissions"
          upgrade-modal-description="On the Free plan, you can try out all paid features only within the form editor. Upgrade your plan to allow users to update their submissions or existing Notion pages via a unique URL, and much more. Gain full access to all advanced features."
        />
      </template>
    </toggle-switch-input>
  </div>
</template>

<script>
export default {
  name: 'NotificationsMessageActions',
  components: {},
  props: {
    modelValue: { type: Object, required: false }
  },
  emits: ['modelValue', 'input'],
  data () {
    return {
      content: this.modelValue ?? {}
    }
  },

  computed: {
    compVal: {
      set (val) {
        this.content = val
        this.$emit('input', this.compVal)
      },
      get () {
        return this.content
      }
    }
  },

  watch: {
    modelValue (val) {
      this.content = val
    }
  },

  created () {
    if (this.compVal === undefined || this.compVal === null) {
      this.compVal = {}
    }
    [
      'include_submission_data',
      'link_see_notion',
      'link_open_form',
      'link_edit_form',
      'views_submissions_count',
      'link_edit_submission'
    ].forEach((keyname) => {
      if (this.compVal[keyname] === undefined) {
        this.compVal[keyname] = true
      }
    })
  },

  methods: {}
}
</script>

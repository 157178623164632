<template>
  <div
    id="app"
    class="bg-white dark:bg-notion-dark"
  >
    <transition
      enter-active-class="linear duration-200 overflow-hidden"
      enter-from-class="max-h-0"
      enter-to-class="max-h-screen"
      leave-active-class="linear duration-200 overflow-hidden"
      leave-from-class="max-h-screen"
      leave-to-class="max-h-0"
    >
      <div
        v-if="announcement && !isIframe"
        class="bg-nt-blue text-white text-center p-3 relative"
      >
        <a
          class="text-white font-semibold"
          href=""
          target="_blank"
        >🚨
          NotionForms beta is over 🚨</a>
        <div
          role="button"
          class="text-white absolute right-0 top-0 p-3 cursor-pointer"
          @click="announcement=false"
        >
          <Icon
            name="heroicons:x-circle-16-solid"
            class="h-6 w-6"
          />
        </div>
      </div>
    </transition>

    <NuxtLoadingIndicator color="#2563eb" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <ToolsStopImpersonation />

    <workspaces-modal
      v-if="showWorkspaceModal"
      :show="showWorkspaceModal"
      :error="error"
      @workspace-added="workspaceAdded"
      @close="showWorkspaceModal=false"
    />
    <ProfitWellRetain />
    <Rewardful />
    <Hotjar />
    <FeatureBase />
    <NotificationsWrapper />
    <SubscriptionModal />
  </div>
</template>

<script>
import { computed } from 'vue'
import { useAppStore } from '~/stores/app'
import { useErrorsStore } from '~/stores/errors'
import WorkspacesModal from './components/notion/WorkspacesModal.vue'
import ProfitWellRetain from '~/components/service/ProfitWellRetain.vue'
import Rewardful from '~/components/service/Rewardful.vue'
import Hotjar from '~/components/service/Hotjar.vue'
import FeatureBase from '~/components/service/FeatureBase.vue'

export default {
  el: '#app',

  name: 'NoteForms',

  components: { FeatureBase, Hotjar, Rewardful, ProfitWellRetain, WorkspacesModal },

  setup () {
    useNoteFormsSeoMeta({
      title: 'Create beautiful forms to fill your Notion databases',
      description: 'Create beautiful forms to fill your Notion databases. Unlimited fields, unlimited submissions. It\'s free and it takes less than 2 minutes to create your first form.',
      ogImage: '/img/social-preview.jpg'
    })
    useHead({
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - NoteForms` : 'NoteForms'
      },
      meta: [
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes'
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'black-translucent'
        }
      ],
      link: [
        {
          rel: 'apple-touch-icon',
          type: 'image/png',
          href: '/favicon.ico'
        }
      ]
    })

    const appStore = useAppStore()
    const errorsStore = useErrorsStore()
    return {
      layout: computed(() => appStore.layout),
      isIframe: useIsIframe(),
      error: computed(() => errorsStore.content)
    }
  },

  data: () => ({
    showWorkspaceModal: false,
    announcement: false,
    alert: {
      type: null,
      autoClose: 0,
      message: '',
      confirmationProceed: null,
      confirmationCancel: null
    },
    navbarHidden: false
  }),

  watch: {
    error () {
      if (this.error) this.showWorkspaceModal = true
    }
  },

  mounted () {
    useCrisp().onCrispInit()
    useCrisp().showChat()
  },

  methods: {
    workspaceAdded () {
      this.$router.push({ name: 'home' })
    },
    hideNavbar (hidden = true) {
      this.navbarHidden = hidden
    }
  }
}
</script>
